<!--  -->
<template>
  <div class="filePage" :class="[isMobileMain ? 'isMobileMain' : '']">
    <div class="protocol-head" v-if="!isMobileMain">
      <div class="protocol-head-container flex-vc flex">
        <a href="/" class="flex-vc flex">
          <div class="logo">
            <img src="../../assets/img/default/upupoo.png" />
          </div>
          <span class="text">upupoo</span>
        </a>
      </div>
    </div>
    <div class="protocol-layout-body">
      <div class="protocol-content" style="padding-top: 0px">
        <h3>UPUPOO团购交易服务协议</h3>
        <p class="text-first">更新日期：2025年2月24日</p>
        <p class="p-title text-first">《UPUPOO团购交易服务协议》（下称“本协议”）是您与武汉小咪网络科技有限公司（下称“我们”或“本公司”）之间关于我们向您提供UPUPOO团购商品交易服务的法律协议。</p>
        <p class="p-title text-first">本协议是基于《UPUPOO用户协议》《UPUPOO隐私协议》以及我们已公布或者将公布的相关协议规范（简称为“UPUPOO服务条款”）制定，与其是不可分割的；同时，我们不时发布的关于UPUPOO团购的相关协议、规则、公告、须知等各类规则也是本协议的一部分，您在使用UPUPOO团购服务的同时应遵守本协议、UPUPOO服务条款及前述相关协议、规则等所有条款。</p>
        <p class="p-title text-first">本协议中与您的权益（可能）存在重大关系的条款（包括相关免除我们责任的条款、限制用户权利的条款、约定的争议解决方式及司法管辖条款等），我们已采用字体加粗的方式来特别提醒您，请您留意重点查阅。您应当在确认充分阅读字体加粗条款，明确理解的前提下使用本服务。</p>
        <p class="p-title text-first">我们可能会根据产品的整体规划等，对团购服务及/或本协议进行修改或变更，若您不同意本服务及/或本协议的前述修改或变更，您可停止使用本服务。您使用本服务且在此过程中未向我们提出关于本协议的任何异议，即视为您接受并同意本协议所有条款，包括但不限于前述修改及变更。</p>
        <p class="p-title text-first">如果您未满18周岁，请在法定监护人的陪同下阅读并决定是否同意本协议。</p>
        <p class="text-first">您有权在UPUPOO团购上享受商品及/或服务的浏览、购买与评价等服务（以下统称为“服务”），具体您可进入UPUPOO团购查看并使用。</p>
        <p class="content-title text-first">一、交易条款</p>
        <p class="text-first">1.关于商品信息</p>
        <p class="text-first">（1）商品信息的披露</p>
        <p class="text-first">我们将努力以真实、准确地向您披露UPUPOO团购内的商品信息，以保障您在UPUPOO团购上的合法权益及良好体验。同时，鉴于UPUPOO团购具备商品种类繁多、商品信息量大及信息网络环境下信息与实物相分离的特点，并受限于互联网技术因素等客观原因，商品信息页面显示可能存在一定的滞后性或差错，您对此表示知悉并理解。</p>
        <p class="text-first">（2）商品信息的变更</p>
        <p class="text-first">商品价格、数量、介绍、是否有货等商品信息随时都有可能发生变动，任何变动我们不作特别通知，请您至商品页面自行查看，并在使用我们提供的服务时谨慎判断。</p>
        <p class="text-first">2.关于订单</p>
        <p class="text-first">（1）订单信息的准确性</p>
        <p class="text-first">在您提交订单时，请仔细确认所购商品的品名、价格、数量、型号、规格、尺寸、商品瑕疵或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的收货地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</p>
        <p class="p-title text-first">您的购买行为应当基于真实的消费需求，不得存在对商品实施恶意购买、恶意维权，或恶意下单以获取不正当利益（包括但不限于大量下单以获取优惠权益或赠品用于倒卖、转让等）等扰乱UPUPOO团购正常交易秩序的行为。基于维护UPUPOO团购交易秩序及交易安全的需要，我们发现上述情形时可主动执行取消/关闭相关交易订单、终止双方买卖合同并要求恢复原状（退货退款），以及限制您的账号功能使用等操作。</p>
        <p class="text-first">（2）订单信息的使用</p>
        <p class="text-first">您同意并保证：为了更好的为您提供服务，我们会记录您在选购商品过程中在线填写的所有信息，包括但不限于如下：交易的商品信息、收货人信息（收货人姓名、收货地址及其联系电话）。此外，我们也可能会再收集一些其他与订单相关的信息，包括但不限于：交易金额、下单时间、订单编号、订单状态、支付方式、支付流水号、支付状态，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。</p>
        <p class="text-first">当您成功下单后，如商品系第三方卖家提供的或商品需要第三方配送的，我们为了顺利、安全、准确向您完成商品的交付及配送，我们合作的第三方配送公司在发货及配送环节内不可避免地会获知您的相关配送信息。</p>
        <p class="text-first">我们向您承诺，我们会以最大努力保障您的个人信息安全，并严格要求合作的第三方配送公司对您的个人信息保密，只可以顺利完成交易之目的获悉和使用（若有需要可提供给相关服务提供方），不得对外泄露或做其他任何用途。</p>
        <p class="text-first">（3）关于订单的生效及取消</p>
        <p class="text-first">UPUPOO后台系统生成的订单信息是计算机信息系统根据您填写的内容和操作自动生成的数据，您的订单提交并足额支付商品价款后，双方之间的合同才成立，只有您购买的商品达到成团条件才团购成功，双方之间的买卖合同才生效。<em>若您未能在指定时间内完成付款我们有权取消订单，您应自行承担未能及时付款商品截团无法继续支付等后果；若您付款后商品未能达到成团条件我们有权作流团处理，流团后将关闭订单并全额退款，退款会在3个工作日内退回到您的支付账户。</em></p>
        <p class="text-first">您理解并同意：由于市场变化及各种以合理商业努力难以控制因素的影响，即使我们做出最大的努力，您提交的订单中出现商品生产问题、价格标示错误等情况仍无法避免。在前述情形出现时，您与我们均有权取消订单；若您已经付款，我们将及时为您办理退款。</p>
        <p class="content-title text-first">二、配送与交付</p>
        <p class="text-first">您理解并同意，您在提交订单时完整、清楚、准确地填写有效的收货人姓名、联系电话及收货地址等配送信息是我们及时发货的必要前提条件。因您填写的配送信息不详细或不准确、商品送达无人签收或拒收造成的迟延配送或无法配送的责任及重复配送所产生的费用和相关的后果将由您自行承担；因您变更任一配送信息而造成的损失由您自行承担。</p>
        <p class="text-first">1.关于发货时间</p>
        <p class="text-first">您知悉并理解，我们在相应商品信息页面上展示的发货时间为参考时间，该商品的具体发货时间会因受制作周期、物流周期、质检返工、不可抗力（本协议所称不可抗力，是指不能预见、不能克服并不能避免的客观事件，包括但不限于自然灾害、政府行为、时疫、意外事件、社会异常事件、罢工、恐怖事件、骚乱、战争、网络原因、黑客攻击等）等诸多因素影响存在误差，导致商品的实际发货时间可能延迟或无法提供预估发货时间，您对上述情形表示同意及理解。</p>
        <p class="text-first">2.关于配送费用及可配送区域</p>
        <p class="text-first">（1）配送费用的确认</p>
        <p class="text-first">商品的配送费用主要取决于您所提供的收货地址及所使用的第三方配送公司的收费规则等，各商品的配送费用具体以您提交订单时生成的订单信息页面展示为准，请在支付前对相关费用信息进行确认。</p>
        <p class="text-first">（2）可配送区域</p>
        <p class="text-first">商品的可配送区域为中华人民共和国大陆地区，除偏远地区(新疆、西藏、内蒙古、青海、宁夏、甘肃)，暂不支持中华人民共和国香港特别行政区、澳门特别行政区及台湾地区。</p>
        <p class="text-first">3.交付及验收</p>
        <p class="text-first">（1）商品的交付</p>
        <p class="text-first">商品交付的时间根据您购买商品的交付方式而不同。商品需要第三方配送公司为您进行配送的，配送公司系统或单据记录的收货人签收时间为商品交付时间；商品以在线传输方式交付的，以订单显示的发货时间为商品交付时间。</p>
        <p class="text-first">请您确保订单中填写的配送信息（包括但不限于收货人姓名、联系电话及收货地址等）真实、详细、准确、有效。因用户提供的配送信息错误导致未收到商品的，由用户承担不利后果。</p>
        <p class="text-first">在签收商品时，请您本人或您指定的收货人亲自与第三方配送公司的工作人员进行当面查验，确认无误后进行签收。若您或上述收货人委托他人签收商品或配送公司已按您或收货人指示将商品置于指定地点的，视为收货人签收，商品交付完成。</p>
        <p class="text-first">您或您指定的收货人无正当理由拒签、退货或因个人原因（收货地址错误、无人签收，联系电话关机、停机，收货人不接电话等个人原因）造成商品无法及时送达，导致商品不适宜二次销售的，相关费用及商品损失均由您自行承担，如给我们或第三方造成损失的，您应当赔偿前述损失。</p>
        <p class="text-first">（2）商品的验收</p>
        <p class="text-first">a. 您或您指定的收货人签收商品时，应当对商品进行验收。</p>
        <p class="text-first">b.涉及商品表面一致的事项，您或您指定的收货人应当在签收商品时进行验收。本条所称“表面一致”，是指凭肉眼即可判断所收到的商品表面状况良好且与商品信息页面描述相符，表面一致的判断范围包括但不限于商品的形状、大小、数量、重量等。</p>
        <p class="text-first">c. 您或您指定的收货人签收商品时发现表面不一致的，有权拒绝签收商品，同时请您及时与客服人员取得联系，进行处理。</p>
        <p class="text-first">d.对于需要打开包装查验的商品，您或您指定的收货人应当要求第三方配送公司工作人员当场监督并打开包装查验，或参照商品信息页面录制相应的开箱验收视频，如发现表面不一致，应当在签收单上明确备注详细情况并让配送公司工作人员签字确认退回，或及时与客服人员取得联系，提交开箱验收视频及验收异议，经客服人员同意后寄回商品。您未进行拆装查验即签收商品或未在指定时间内提交合格的开箱验收视频及验收异议的，视为您已查验无误。</p>
        <p class="text-first">e. 商品毁损、灭失等的风险自您或您指定的收货人签收商品之时起，需由您自行承担。</p>
        <p class="content-title text-first">三、支付及发票条款</p>
        <p class="text-first">1.关于支付服务</p>
        <p class="text-first">您有权自行选择使用UPUPOO团购可支持的支付方式（具体支持的支付方式以订单提交后的付款页面显示为准），您理解并确认支付服务由具备合法资质的第三方支付服务提供方为您提供，该等支付服务的使用条件及规范由您与支付服务提供方确定，我们不对支付服务承担任何责任。</p>
        <p class="text-first">2.关于发票</p>
        <p class="text-first">如需申请发票，请联系客服人员申请发票开具事宜。</p>
        <p class="text-first">如您在获取发票后选择退货退款，您已获取的发票将被作废，即您手中的发票将失效</p>
        <p class="content-title text-first">四、售后服务</p>
        <p class="p-title text-first">1.关于退货</p>
        <p class="text-first">您确认并同意，不同的商品适用于不同的售后服务规则，您在购买商品前需仔细阅读相关商品信息页面展示的售后服务规则。</p>
        <p class="text-first">（1）就适用于七天无理由退货制度的商品，您可于签收商品之日起的七天内在订单信息页发起退货申请。<em>您应按照退货申请页面规定的退货时间完成退货，并及时告知客服人员退货商品的物流信息（如您自行寄回退货商品，商品的在途风险应由您或您委托的第三方配送公司承担）。您应保证退还的商品完好（能够保持原有品质、功能，商品本身、配件、商标标识齐全的）且不影响二次销售。因您个人原因导致超时未完成退货，对同一问题或维权原因再次主张要求退换货的，应与客服人员协商一致后再行发起。</em></p>
        <p class="text-first">（2）因部分商品性质特殊，不适用七天无理由退换货制度（具体以各商品信息页面展示为准）。</p>
        <p class="text-first">（3）我们会在相关商品信息页面对该商品是否适用七天无理由退货制度作出提示，请您在购买前仔细阅读商品信息页面，如您选择提交订单，则视为您同意该商品信息页面所展示的退货规则并愿意承担因此产生的相应费用。</p>
        <p class="text-first">（4）若出现商品质量问题的退货需求：</p>
        <p class="text-first">a. 若您在签收商品后发现存在质量问题的，在商品载明的质量保证期内您可联系客服人员申请办理退换货；</p>
        <p class="text-first">b. 如经客服人员确认商品存在质量问题的，我们将优先为您提供换货服务；如商品无库存，我们将为您提供退货退款服务；</p>
        <p class="text-first">（5）我们处理上述退货申请时会根据您提供的商品问题及证据进行判断，您应对所提供的证据的真实性、关联性、完整性、准确性和及时性负责。<em>因非我们原因造成的任何商品质量问题或瑕疵，我们不就此承担售后服务。</em></p>
        <p class="text-first">（6）<em>如您申请退货的订单中包含赠品的，除客服人员另外同意的，无论您的申请为全部退货或部分退货，您均应将赠品全部完好退还，否则我们将拒绝您的该等申请，及/或</em>按赠品价值自应退价款中扣除相应价款。</p>
        <p class="text-first">赠品的价值计算：若我们对赠品价值进行了事先声明的，按其声明价值计算；事先未予声明的，将按照同品类商品的市场公允价值酌定。</p>
        <p class="p-title text-first">2.退换货的运费规则</p>
        <p class="p-title text-first">非质量问题导致的退换货运费，用户需承担退回运费，商品寄回时需支付运费。采取到付方式退货的，我们有权拒绝签收。</p>
        <p class="p-title text-first">因质量问题导致的退换货运费，商品寄回时需要先联系客服，获得同意后采取到付方式寄回。用户未经客服或我们同意采取运费垫付的，我们有权拒绝签收或不返还运费。</p>
        <p class="p-title text-first">3.关于退款</p>
        <p class="text-first">经我们同意您的退货申请，收到您的退货商品并确认无误后，我们会尽快为您办理退款。退款按您支付的原路退回，请您留意余额变化。若超过30个工作日，您仍未收到退款，请及时联系客服人员。</p>
        <p class="content-title text-first">五、评价</p>
        <p class="text-first">您有权在UPUPOO提供的评价系统中对您购买的商品进行评价。您的所有评价行为应遵守法律法规、本协议的相关规定，评价内容应当客观真实，与交易的商品具有关联性，不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；您不得以不正当方式帮助他人提升信用或利用评价权利对其他主体实施威胁、敲诈勒索。我们可按照相关规定对您实施上述行为所产生的评价信息采取必要处置措施。</p>
        <p class="content-title text-first">六、数据权属及知识产权</p>
        <p class="text-first">1. 用户信息。您承诺，您对您在UPUPOO发布的文字、资讯、资料、音乐、照片、图形、视讯、信息或其他信息资料（包括但不限于用户评价、用户咨询、各类话题文章等信息内容，统称为“用户信息”）拥有相应合法权利，不会侵犯任何第三人的知识产权等合法权益，否则，我们可对您发布的用户信息依法或依本协议进行删除或屏蔽。</p>
        <p class="text-first">2. 用户信息授权。针对您的用户信息的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），您同意授权我们及其关联公司一项全球范围内、免费、非独家、可再许可（通过多层次）的使用权。您同意我们有权在通过网络平台进行推广时引用用户评论、服务反馈等中的用户信息。</p>
        <p class="text-first">同时，您授权我们以UPUOO的名义或委托专业第三方对侵犯您上传发布的享有知识产权等合法权益的用户信息进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，我们有权对维权事宜独立作出决策并独立实施。</p>
        <p class="text-first">3. 除法律另有强制性规定外，未经我们明确的特别书面许可，任何主体不得以任何方式非法地、全部或部分复制、转载、引用、链接、抓取或以其他方式使用UPUPOO团购的信息内容，否则，我们有权追究其法律责任。</p>
        <p class="text-first">4. UPUPOO团购所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是我们或其内容提供者的财产，受中国和国际版权法的保护。UPUPOO团购上所有内容的汇编是我们的排他财产，受中国和国际版权法的保护。UPUPOO团购上所有软件都是我们或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。</p>
        <p class="content-title text-first">七、通知</p>
        <p class="text-first">在注册账号并接受UPUPOO服务时，您应该向我们提供真实有效的联系方式（包括但不限于您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</p>
        <p class="text-first">您在使用UPUPOO团购下单时自行填写并设置的默认收货地址信息（包括但不限于收货人姓名、收货地址及其联系电话），也作为您的有效联系方式。</p>
        <p class="text-first">我们将向您的上述联系方式的其中之一或其中若干，及/或以电子的方式（包括但不限于在UPUPOO公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件）向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</p>
        <p class="text-first">您有权通过您注册账号时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过我们提供的相应退订功能进行退订。</p>
        <p class="content-title text-first">八、服务中止或终止</p>
        <p class="text-first">1.如我们发现或接举报发现您存在以下情形，我们有权中止或终止本协议及向您提供本服务，上述权利的行使无期限限制：</p>
        <p class="text-first">（1） 您主动中止或终止本服务的；</p>
        <p class="text-first">（2） 您存在或我们经独立判断后认为您存在任何违反国家法律法规、监管政策、本协议或其他UPUPOO服务条款的行为，或存在其他影响或可能影响UPUPOO或/及其关联公司的声誉、利益的行为的；</p>
        <p class="text-first">（3） 根据法律法规、监管政策的规定或有权机关的要求；</p>
        <p class="text-first">（4） 为维护账号与系统安全等紧急情况之需要；</p>
        <p class="text-first">（5） 不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制导致的暂时关闭、病毒侵袭等影响互联网正常运行之情形）；</p>
        <p class="text-first">（6） 其他可能导致本服务中止或终止的情形。</p>
        <p class="text-first">2.当发生前述中止或终止的情况时，您认可如下处理方式：</p>
        <p class="text-first">（1） 如您在UPUPOO团购内有正在进行中的交易，我们届时将视情况进行合理处理；</p>
        <p class="text-first">（2） 如因您违反本协议导致终止的，我们有权视情况要求您承担相应的违约责任（包括但不限于冻结订单费用、封禁账号等）；</p>
        <p class="text-first">（3） 除法律法规另有规定或我们另有说明外，我们无需向您和第三人承担任何责任。</p>
        <p class="content-title text-first">九、其他</p>
        <p class="text-first">本协议的解释，效力及纠纷的解决，适用于中华人民共和国大陆地区法律法规。如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。仲裁裁决为一裁终局，对各方均有法律约束力。</p>
        <p class="text-first">本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      isMobileMain: false,
    };
  },
  created() {
    this.isMobileMain = this.isMobile();
    console.log(this.isMobileMain);
  },
  computed: {},
  mounted() {
    if (this.isMobileMain) {
      this.init();
      this.getRem();
    }
  },
  methods: {
    // 判断是不是移动端
    isMobile() {
      const UA = window.navigator.userAgent.toLowerCase();
      let ios = UA.match(/iphone/i) == "iphone" || UA.match(/ipad/i) == "ipad" ? !0 : !1,
        adr = UA.match(/android/i) == "android" ? !0 : !1;
      return ios || adr;
    },
    getRem() {
      (function (doc, win) {
        let docEl = doc.documentElement,
          resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function () {
            let clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
              docEl.style.fontSize = "100px";
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
            }
          };

        recalc();
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
      })(document, window);
    },
    init() {
      let app = document.querySelector("#app");
      app.style.minWidth = "100vw";
      app.style.width = "100vw";
      app.style.minHeight = "100vh";
      app.style.height = "auto";
      if (app.querySelectorAll(".__header")[0]) app.querySelectorAll(".__header")[0].style.display = "none";
      if (app.querySelectorAll(".header_block")[0]) app.querySelectorAll(".header_block")[0].style.display = "none";
      if (app.querySelectorAll(".footer")[0]) app.querySelectorAll(".footer")[0].style.display = "none";
      if (app.querySelectorAll(".box")[0]) app.querySelectorAll(".box")[0].style.minWidth = "100vw";
    },
  },
};
</script>

<style lang="scss" scoped>
.table_p {
  table-layout: fixed;
  empty-cells: show;
  border-collapse: collapse;
  margin: 12px auto;
  font-size: 24px;
}

.th_1 {
  width: 25%;
}

.th_2 {
  width: 10%;
}

.th_3 {
  width: 45%;
}

.th_4 {
  width: 20%;
}

td,
th {
  border: 1px solid #cad9ea;
  text-align: center;
}

span {
  display: inline-block;
}

.content-title {
  font-size: 24px !important ;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
  line-height: 48px;
  margin-top: 32px;
}

.text-first {
  text-indent: 4px;
  margin-top: 32px;
}
em {
  font-style: normal;
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
}
.span-title {
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
  display: inline !important;
}

.p-title {
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
}

.no-indext {
  text-indent: 0px !important;
}

.p-behind {
  display: inline !important;
}

.text-p {
  text-indent: 4px;
  margin-top: 32px;
}

.protocol {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
}

.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
.isMobileMain {
  .table_p {
    table-layout: fixed;
    empty-cells: show;
    border-collapse: collapse;
    margin: 0.12rem auto;
    font-size: 0.28rem;
  }

  .th_1 {
    width: 25%;
  }

  .th_2 {
    width: 10%;
  }

  .th_3 {
    width: 45%;
  }

  .th_4 {
    width: 20%;
  }

  td,
  th {
    border: 1px solid #cad9ea;
    text-align: center;
  }

  span {
    display: inline-block;
  }

  .content-title {
    font-size: 0.32rem !important;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: rgba(29, 29, 29, 1);
    line-height: 0.48rem !important;
    margin-top: 0.32rem;
  }

  .text-first {
    text-indent: 0.4rem;
    margin-top: 0.32rem;
  }
  em {
    font-style: normal;
    font-weight: bold;
    color: rgba(29, 29, 29, 1);
  }
  .span-title {
    font-weight: bold;
    color: rgba(29, 29, 29, 1);
    display: inline !important;
  }

  .p-title {
    font-weight: bold;
    color: rgba(29, 29, 29, 1);
  }

  .no-indext {
    text-indent: 0px !important;
  }

  .p-behind {
    display: inline !important;
  }

  .text-p {
    text-indent: 0.4rem;
    margin-top: 0.32rem;
  }

  .protocol {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
  }

  .protocol-content {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    // border-top: 0.01rem solid #d8d8d8;
    font-size: 0.32rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #5f5f5f;
    padding: 0 0.3rem;
    line-height: 0.48rem;
    text-align: left;

    padding-bottom: 1rem;
    overflow-y: auto;
  }
  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 0.02rem 0.04rem 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 100%;
    height: 0.6rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 0.42rem;
    height: 0.42rem;
    line-height: 0.42rem;
    margin-left: 0.1rem;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 0.28rem;
    color: #4a4a4a;
    margin-left: 0.1rem;
    line-height: 0.42rem;
  }

  .protocol-layout-body {
    width: 100%;
    margin: 0.22rem auto 0;
    color: #000;
    font-size: 0.32rem;
  }

  .protocol-layout-body h3 {
    padding: 0.04rem 0 0.24rem;
    font-size: 0.32rem;
    border-bottom: 0.01rem solid #e7e7eb;
    margin-bottom: 0.24rem;
  }

  .protocol-layout-body p {
    margin-bottom: 0.2rem;
    font-size: 0.28rem;
    line-height: 0.42rem;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
